/*
 * @Author: 马秋顺 1813637761@qq.com
 * @Date: 2022-12-15 14:39:43
 * @LastEditors: 马秋顺 1813637761@qq.com
 * @LastEditTime: 2022-12-15 15:20:42
 * @FilePath: \B2B\src\views\yearScanResultH5\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import service from "@/utils/request";
export function shopScanning(data) {
return service({
    method: "post",
    url: "/cts/ctsApi2.api",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
});
  }