/*
 * @Author: 马秋顺 1813637761@qq.com
 * @Date: 2022-12-12 14:53:33
 * @LastEditors: 马秋顺 1813637761@qq.com
 * @LastEditTime: 2022-12-15 15:23:46
 * @FilePath: \B2B\src\views\yearScanResultH5\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import http from "@/utils/request";
import { Toast } from 'vant';
import 'vant/lib/index.css';
import { shopScanning } from "./api";
Vue.use(Toast);
export default {
  name: "yearScanResultH5",
  data() {
    return {
      phone: "", //手机号
      userName: "", //用户姓名
      account: "",
      type: "", //品类
      userId: "", //用户标识
    }
  },
  mounted() {
    Toast.loading({ message: '加载中...', duration: 0 });
    this.phone = this.getQueryString('phone');
    this.account = this.getQueryString("account");
    this.type = this.getQueryString("type");
    this.userId = this.getQueryString("userId");
    shopScanning({
      userId: this.userId,
      phone: this.phone,
      type: this.type,
      account: this.account,
      serviceCode: "shopScanning"
    }).then(res => {
      Toast.clear();
      if(res.data.code == 0){
        this.userName = (res.data.data && res.data.data.contact) || "";
      }else{
        Toast.fail(res.data.msg);
      }
    }).catch(() => Toast.clear());
  },
  methods: {
    getQueryString(name) {
      let u = arguments[1] || window.location.href,
        reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"),
        r = u.substr(u.indexOf("?") + 1).match(reg);
      return r != null ? decodeURI(r[2]) : "";
    },
    handleCloseClick(flag){
      if(window.ReactNativeWebView){
        window.ReactNativeWebView.postMessage(`${flag}&${this.phone}`);
      }
    },
  }
}